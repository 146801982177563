import React, { useEffect, useState } from "react";
import { CarSubModels } from "../Services/apiServices";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../utlis/Loader";
import { motion, useAnimation } from "framer-motion";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import Breadcrumb from "../utlis/Breadcrumb";
import CarModalHeader from "./CarModalHeader";

const CarModification = () => {
  const { models, submodels } = useParams();
  const [CarModification, setCarModification] = useState([]);
  const [loader, setLoader] = useState(true);
  const navigate = useNavigate();
  const controls = useAnimation();

  // useEffect to start the animation only after the component has mounted
  useEffect(() => {
    controls.start({ opacity: 1, y: 0 });
  }, [controls]);

  // Trigger animation when the component is in view
  const handleInView = (inView) => {
    if (inView) {
      controls.start({ opacity: 1, y: 0 });
    }
  };

  useEffect(() => {
    let data = {
      company_id: models,
      model_id: submodels,
    };
    CarSubModels(data).then((res) => {
      if (res?.success) {
        setCarModification(res?.data);
        console.log(res?.data);
        setLoader(false);
      } else {
        navigate("/404");
      }
    });
  }, []);

  const breadcrumbs = [
    { label: "Home", url: "/" },
    {
      label: CarModification?.model?.company?.name,
      url: `/${CarModification?.model?.company?.id}`,
    },
    {
      label: CarModification?.model?.name,
      url: "",
    },
  ];

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div className="container mt-4">
          <Breadcrumb items={breadcrumbs} />
          <div className="d-flex align-items-center justify-content-center gap-5">
            <div>
              <img
                src={CarModification?.model?.img_name}
                style={{ width: "200px" }}
                alt=""
              />
            </div>
            <div>
              <h4 style={{ color: "#9B4444" }}>
                {CarModification?.model?.company?.name}
                {CarModification?.model?.name}
              </h4>
            </div>
          </div>
          <hr />
          {/* <CarModalHeader modal_image={CarModification?.model?.img_name}/> */}
          {/* <table className="table table-bordered table-responsive car_modification">
                            <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Region</th>
                                    <th scope="col">Production year</th>
                                    <th scope="col">Engine</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    CarModification?.data?.map((e, index) => {
                                        return (
                                            <tr key={index} style={{ fontSize: "14px" }}>
                                                <td> <a href={`/${models}/${submodels}/${e?.id}`}>{e?.name}</a></td>
                                                <td><a href={`/${models}/${submodels}/${e?.id}`}>{e?.region}</a></td>
                                                <td><a href={`/${models}/${submodels}/${e?.id}`}>{e?.production_year}</a></td>
                                                <td><a href={`/${models}/${submodels}/${e?.id}`}>{e?.engine}</a></td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table> <br/> */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={controls}
            transition={{ duration: 0.5 }}
            style={{ textDecoration: "none" }}
          >
            <motion.div
              className="pro_card"
              onViewportEnter={(viewportEntry) =>
                handleInView(viewportEntry.target)
              }
            >
              <div className="table-responsive-md">
                <table className="table table-bordered table-responsive car_modification table-striped-columns align-middle">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="text-nowrap"
                        style={{ backgroundColor: "#363062", color: "white" }}
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="text-nowrap"
                        style={{ backgroundColor: "#363062", color: "white" }}
                      >
                        Region
                      </th>
                      <th
                        scope="col"
                        className="text-nowrap"
                        style={{ backgroundColor: "#363062", color: "white" }}
                      >
                        Production year
                      </th>
                      <th
                        scope="col"
                        className="text-nowrap"
                        style={{ backgroundColor: "#363062", color: "white" }}
                      >
                        Engine
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {CarModification?.data?.map((e, index) => {
                      return (
                        <tr key={index} style={{ fontSize: "14px" }}>
                          <td style={{ minWidth: "200px" }}>
                            {" "}
                            <a
                              href={`/${models}/${submodels}/${e?.id}`}
                              style={{ color: "#9B4444" }}
                            >
                              {e?.name}
                            </a>
                          </td>
                          <td>
                            <a
                              href={`/${models}/${submodels}/${e?.id}`}
                              style={{ color: "#9B4444" }}
                            >
                              {e?.region}
                            </a>
                          </td>
                          <td>
                            <a
                              href={`/${models}/${submodels}/${e?.id}`}
                              style={{ color: "#9B4444" }}
                            >
                              {e?.production_year}
                            </a>
                          </td>
                          <td>
                            <a
                              href={`/${models}/${submodels}/${e?.id}`}
                              style={{ color: "#9B4444" }}
                            >
                              {e?.engine}
                            </a>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </motion.div>
          </motion.div>
        </div>
      )}
    </>
  );
};

export default CarModification;
