import React from "react";
import one from "../image/u3.webp";
import xxl from "../image/XXL.webp";
import i from "../image/i.webp";

const CarModalHeader = ({ modal_image }) => {
  return (
    // <div
    //   style={{
    //     width: "100%",
    //     height: "332px",
    //     marginTop: "8px",
    //     marginBottom: "16px",
    //     position: "relative",
    //   }}
    // >
    //   <img
    //     src={one}
    //     alt=""
    //     style={{
    //       color: "transparent",
    //       width: "100%",
    //       height: "100%",
    //       objectFit: "cover",
    //       borderRadius: "8px",
    //     }}
    //   />
    //   <div
    //     style={{
    //       position: "absolute",
    //       top: "50%",
    //       left: "50%",
    //       zIndex: 100,
    //       transform: "translate(-50%, -50%)",
    //     }}
    //   >
    //     <h1
    //       style={{
    //         margin: "0px",
    //         letterSpacing: "0px",
    //         fontFamily:
    //           "proxima-nova, ProximaNova, noto, var(--my-gess), GESS, GE-SS, sans-serif",
    //         fontSize: "1.5625rem",
    //         color: "white",
    //         fontWeight: 700,
    //         lineHeight: "48px",
    //         textAlign: "center",
    //       }}
    //     >
    //       Q3
    //     </h1>
    //   </div>
    // </div>


    // <div
    //   style={{
    //     width: "100%",
    //     height: "332px",
    //     marginTop: "8px",
    //     marginBottom: "16px",
    //     position: "relative",
    //   }}
    // >
    //   <img
    //     src={one}
    //     alt=""
    //     style={{
    //       filter: "brightness(50%)", // Adjust brightness for darker appearance
    //       width: "100%",
    //       height: "100%",
    //       objectFit: "cover",
    //       borderRadius: "8px",
    //     }}
    //   />
    //   <div
    //     style={{
    //       position: "absolute",
    //       top: "50%",
    //       left: "50%",
    //       zIndex: 100,
    //       transform: "translate(-50%, -50%)",
    //     }}
    //   >
    //     <h1
    //       style={{
    //         margin: "0px",
    //         letterSpacing: "0px",
    //         fontFamily:
    //           "proxima-nova, ProximaNova, noto, var(--my-gess), GESS, GE-SS, sans-serif",
    //         fontSize: "1.5625rem",
    //         color: "white",
    //         fontWeight: 700,
    //         lineHeight: "48px",
    //         textAlign: "center",
    //       }}
    //     >
    //       Q3
    //     </h1>
    //   </div>
    // </div>
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "332px",
        marginTop: "8px",
        marginBottom: "16px",
        borderRadius: "8px",
        overflow: "hidden", // Make sure the overlay doesn't overflow
      }}
    >
      {/* Background image */}
      <img
        src={xxl}
        alt=""
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          objectFit: "cover",
          zIndex: "1",
          borderRadius: "8px",
        }}
      />

      {/* Black overlay */}
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: "2",
        }}
      ></div>
      {/* Your content */}
      <div
        style={{
          position: "absolute",
          top: "80%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: "3",
          color: "white",
          textAlign: "center",
          fontFamily:
            "proxima-nova, ProximaNova, noto, var(--my-gess), GESS, GE-SS, sans-serif",
          fontSize: "1.5625rem",
          fontWeight: "700",
          lineHeight: "48px",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <img src={modal_image} alt="" />
          <span>AUDIA4 (2006-2008)</span>
        </div>
      </div>
    </div>
  );
};

export default CarModalHeader;
