import React, { useEffect, useState } from "react";
import { CarSubparts } from "../Services/apiServices";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../utlis/Loader";
import { motion, useAnimation } from "framer-motion";
import Breadcrumb from "../utlis/Breadcrumb";

const CarSubParts = () => {
  const [subpart, setSubPart] = useState([]);
  const { models, submodels, parts, subparts } = useParams();
  const [loader, setLoader] = useState(true);
  const navigate = useNavigate();
  const controls = useAnimation();

  // useEffect to start the animation only after the component has mounted
  useEffect(() => {
    controls.start({ opacity: 1, y: 0 });
  }, [controls]);

  // Trigger animation when the component is in view
  const handleInView = (inView) => {
    if (inView) {
      controls.start({ opacity: 1, y: 0 });
    }
  };

  useEffect(() => {
    let data = {
      company_id: models,
      model_id: submodels,
      sub_model_id: parts,
      part_id: subparts,
    };
    CarSubparts(data).then((res) => {
      console.log("kkk", res);
      if (res?.success) {
        setSubPart(res?.data);
        setLoader(false);
      } else {
        navigate("/404");
      }
    });
  }, []);
  const breadcrumbs = [
    { label: "Home", url: "/" },
    {
      label: subpart?.parts?.sub_model?.model?.company?.name,
      url: `/${subpart?.parts?.sub_model?.model?.company?.id}`,
    },
    {
      label: subpart?.parts?.sub_model?.model?.name,
      url: `/${subpart?.parts?.sub_model?.model?.company?.id}/${subpart?.parts?.sub_model?.model?.id}`,
    },
    {
      label: subpart?.parts?.sub_model?.name,
      url: `/${subpart?.parts?.sub_model?.model?.company?.id}/${subpart?.parts?.sub_model?.model?.id}/${subpart?.parts?.sub_model?.id}`,
    },
    { label: subpart?.parts?.name, url: "" },
  ];

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div className="container mt-4">
          <Breadcrumb items={breadcrumbs} />
          <div className="d-flex align-items-center justify-content-center gap-5 flex-wrap">
            <div className="">
              <img
                src={subpart?.parts?.sub_model?.model?.img_name}
                style={{ width: "200px" }}
                alt=""
              />
            </div>
            <div className="">
              <h4>
                {subpart?.parts?.name}
                {subpart?.parts?.sub_model?.model?.company?.name}{" "}
                {subpart?.parts?.sub_model?.model?.name}{" "}
                {subpart?.parts?.sub_model?.name}
              </h4>
              <div style={{ fontSize: "12px" }}>
                <span>Region : {subpart?.parts?.sub_model?.region}</span> <br />
                <span>
                  Production year : {subpart?.parts?.sub_model?.production_year}
                </span>{" "}
                <br />
                <span>
                  Engine type : {subpart?.parts?.sub_model?.engine}
                </span>{" "}
                <br />
                <span>Body type : SEDAN</span>
              </div>
            </div>
          </div>
          <div className="row">
            {subpart?.data?.map((e, index) => {
              console.log("sub.....", e);
              return (
                <motion.a
                  initial={{ opacity: 0, y: 20 }}
                  animate={controls}
                  key={index}
                  href={`/${models}/${submodels}/${parts}/${subparts}/${e?.id}`}
                  className="col-xl-2 col-lg-3 col-md-4 col-12"
                  transition={{ duration: 0.5 }}
                  style={{ textDecoration: "none" }}
                >
                  <motion.div
                    className="pro_card"
                    onViewportEnter={(viewportEntry) =>
                      handleInView(viewportEntry.target)
                    }
                  >
                    <div className="product-card" style={{
                      backgroundColor: e?.part_detail_count === 0 ? 'lightgreen' : 'white'
                    }}>
                      <div className="product-tumb">
                        <img src={e?.img_name ? e?.img_name : e?.image} alt="" />
                      </div>
                      <hr />
                      <div className="subproduct-details">
                        <a
                          href={`/${models}/${submodels}/${parts}/${subparts}/${e?.id}`}
                        >
                          <h3><strong>{index + 1}</strong></h3> {e?.name}
                          {e?.part_detail_count > 0 && (
                            <h6 style={{ padding: "4px", fontWeight: "700", fontSize: "18px", color: "red", }}>{e?.part_detail_count}</h6>
                          )}
                        </a>
                      </div>
                    </div>
                  </motion.div>
                </motion.a>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default CarSubParts;
