import React, { useEffect, useState } from "react";
import { CarCompanies } from "../Services/apiServices";
import { useNavigate } from "react-router-dom";
import Loader from "../utlis/Loader";
import { motion, useAnimation } from "framer-motion";
import Breadcrumb from "../utlis/Breadcrumb";

const CarComapnyList = () => {
  const [carCompany, setCarCompany] = useState([]);
  const [loader, setLoader] = useState(true);
  const navigate = useNavigate();
  const controls = useAnimation();
  const breadcrumbs = [{ label: "Home", url: "/" }];

  // useEffect to start the animation only after the component has mounted
  useEffect(() => {
    controls.start({ opacity: 1, y: 0 });
  }, [controls]);

  // Trigger animation when the component is in view
  const handleInView = (inView) => {
    if (inView) {
      controls.start({ opacity: 1, y: 0 });
    }
  };

  useEffect(() => {
    CarCompanies().then((res) => {
      if (res?.success) {
        setCarCompany(res?.data);
        setLoader(false);
      } else {
        navigate("/404");
      }
    });
  }, []);
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div className="container mt-4">
          <Breadcrumb items={breadcrumbs} />
          <div className="row">
            {carCompany?.map((e, index) => {
              console.log(e);
              return (
                // <div className='col-lg-2 col-md-4 col-sm-6 col-12' key={index}>
                <motion.div
                  key={index}
                  className="col-lg-2 col-md-4 col-sm-6 col-12 move-up"
                  initial={{ opacity: 0, y: 20 }}
                  animate={controls}
                  transition={{ duration: 0.5 }}
                  style={{ textDecoration: "none" }}
                >
                  <motion.div
                    className="pro_card"
                    onViewportEnter={(viewportEntry) =>
                      handleInView(viewportEntry.target)
                    }
                  >
                    <a href={`/${e?.id}`}>
                      <div className="product-card">
                        <div className="product-tumb">
                          <img src={e?.image} alt="" />
                        </div>
                        <hr />
                        <div className="product-details">
                          <a href={`/${e?.id}`}>{e?.name}</a>
                        </div>
                      </div>
                    </a>
                  </motion.div>
                </motion.div>
                // </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default CarComapnyList;
