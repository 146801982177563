import React from "react";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";

const Breadcrumb = ({ items }) => {
    return (
        <h6 className="p-3 breadcrumb-link" style={{
            backgroundColor: "#f8f9fa", marginTop: "100px", position: "sticky", top: "7.6%",boxShadow:"rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
            , borderRadius:"15px" , zIndex:"5"
        }}>
            {
                items.map((item, index) => (
                    <React.Fragment key={index}>
                        {index > 0 && <MdKeyboardDoubleArrowRight size={24} />
                        }
                        {index === items.length - 1 ? (
                            <span style={{ color: "#363062" }}>{item.label}</span>
                        ) : (
                            <a href={item.url} className="breadcrumb-link-label">{item.label}</a>
                        )}
                    </React.Fragment>
                ))
            }
        </h6 >
    );
};

export default Breadcrumb;
