import { createSlice } from "@reduxjs/toolkit";

export const productsSlice = createSlice({
  name: "OEMCATELOG",
  initialState: {
    add_carmodel: [],
  },

  reducers: {
    addCarModel: (state, { payload }) => {
      state.add_carmodel = payload
    },
 
  }
})

export const {
  addCarModel,

} = productsSlice.actions;

export default productsSlice.reducer;
