import React, { useEffect, useState } from 'react'
import { Carparts } from '../Services/apiServices';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../utlis/Loader';
import { motion, useAnimation } from "framer-motion";
import Breadcrumb from '../utlis/Breadcrumb';


const CarSparepartList = () => {
    const [part, setPart] = useState([]);
    const { models, submodels, parts } = useParams();
    const [loader, setLoader] = useState(true);
    const navigate = useNavigate();
    const controls = useAnimation();

    // useEffect to start the animation only after the component has mounted
    useEffect(() => {
        controls.start({ opacity: 1, y: 0 });
    }, [controls]);

    // Trigger animation when the component is in view
    const handleInView = (inView) => {
        if (inView) {
            controls.start({ opacity: 1, y: 0 });
        }
    };

    useEffect(() => {
        let data = {
            company_id: models,
            model_id: submodels,
            sub_model_id: parts
        }
        Carparts(data).then((res) => {
            if (res?.success) {
                setPart(res?.data)
                setLoader(false)
            } else {
                navigate('/404');
            }
        })
    }, [])

    const breadcrumbs = [
        { label: "Home", url: "/" },
        {
            label: part?.submodel?.model?.company?.name,
            url: `/${part?.submodel?.model?.company?.id}`,
        },
        {
            label: part?.submodel?.model?.name,
            url: `/${part?.submodel?.model?.company?.id}/${part?.submodel?.model?.id}`,
        },
        { label: part?.submodel?.name, url: "" },
    ];

    return (
        <>
            {
                loader ?
                    <Loader /> :
                    <div className="container mt-4">
                        <Breadcrumb items={breadcrumbs} />
                        <div className='d-flex align-items-center justify-content-center gap-5 flex-wrap'>
                            <div>
                                <img src={part?.submodel?.model?.img_name} style={{ width: "200px" }} alt="" />
                            </div>
                            <div>
                                <h4>{part?.submodel?.model?.company?.name} {part?.submodel?.model?.name} {part?.submodel?.name}</h4>
                                <div style={{ fontSize: "12px" }}>
                                    <span>Region : {part?.submodel?.region}</span> <br />
                                    <span>Production year : {part?.submodel?.production_year}</span> <br />
                                    <span>Engine type : {part?.submodel?.engine}</span> <br />
                                    <span>Body type : SEDAN</span>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            {
                                part?.data?.map((e, index) => {
                                    return (
                                        <motion.a
                                            key={index}
                                            className="col-md-3 col-sm-4 col-lg-2 col-xs-12"
                                            initial={{ opacity: 0, y: 20 }}
                                            animate={controls}
                                            href={`/${models}/${submodels}/${parts}/${e?.id}`}
                                            transition={{ duration: 0.5 }}
                                            style={{ textDecoration: "none" }}
                                        >
                                            <motion.div
                                                className="pro_card"
                                                onViewportEnter={(viewportEntry) =>
                                                    handleInView(viewportEntry.target)
                                                }
                                            >
                                                <div className="product-card" style={{
                                                    backgroundColor: e?.part_details_through_count === 0 ? 'lightgreen' : 'white'
                                                }}>
                                                    <div className="product-tumb">
                                                        <img src={e?.img_name ? e?.img_name : e?.image} alt="" />
                                                    </div>
                                                    <hr />
                                                    <div className="product-details">
                                                        <a href={`/${models}/${submodels}/${parts}/${e?.id}`}><span><strong style={{ marginRight: "4px", fontSize: "24px" }}>{index + 1}</strong></span> - {e?.name}</a>
                                                    </div>
                                                    {e?.part_details_through_count > 0 && (
                                                        <h6 style={{ padding: "4px", textAlign: "center", fontWeight: "700", fontSize: "18px", color: "red", }}>{e?.part_details_through_count}</h6>
                                                    )}
                                                </div>
                                            </motion.div>
                                        </motion.a>
                                    )
                                })
                            }
                        </div>
                    </div>
            }
        </>
    )
}

export default CarSparepartList