import React from 'react'
import logo from '../image/mechx.png'

const Header = () => {
    return (
        <>
            <>
                <div className="navigation-wrap bg-light start-header start-style">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <nav className="navbar navbar-expand-md">
                                    <a
                                        className="navbar-brand"
                                        href="/"
                                    >
                                        <img src={logo} alt="" />
                                    </a>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </>

        </>
    )
}

export default Header