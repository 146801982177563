import axios from "axios";

// const BASE_URL = "https://adminbood.hypehy.com"
// const BASE_URL = "http://192.168.100.51:8000"
const BASE_URL = "https://tofun.online"


export const CarCompanies = async () => {
    const response = await axios.get(`${BASE_URL}/api/companies`);
    if (response?.data) {
        return response.data;
    } else {
        console.log("error");
    }
};

export const CarModel = async (id) => {
    const response = await axios.get(`${BASE_URL}/api/models/${id}`);
    console.log(response);
    if (response?.status) {
        return response.data;
    } else {
        console.log("error");
    }
};

export const CarSubModels = async (data) => {
    const response = await axios.get(`${BASE_URL}/api/submodels/${data.company_id}/${data.model_id}`);
    if (response?.status) {
        return response.data;
    } else {
        console.log("error");
    }
};

export const Carparts = async (data) => {
    const response = await axios.get(`${BASE_URL}/api/parts/${data.company_id}/${data.model_id}/${data.sub_model_id}`);
    if (response?.status) {
        return response.data;
    } else {
        console.log("error");   
    }
};

export const CarSubparts = async (data) => {
    const response = await axios.get(`${BASE_URL}/api/subparts/${data.company_id}/${data.model_id}/${data.sub_model_id}/${data.part_id}`);
    if (response?.status) {
        return response.data;
    } else {
        console.log("error");
    }
};

export const CarPartDetails = async (data) => {
    const response = await axios.get(`${BASE_URL}/api/partsdetails/${data.company_id}/${data.model_id}/${data.sub_model_id}/${data.part_id}/${data.sub_part_id}`);
    if (response?.status) {
        return response.data;
    } else {
        console.log("error");
    }
};
export const PartNumberSave = async (data) => {
    const response = await axios.post(`${BASE_URL}/api/manually-pn-update`,data,{
        timeout : 3600000
    });
    if (response?.status) {
        return response.data;
    } else {
        console.log("error");
    }
};